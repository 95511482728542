[data-section="examples"] .sectionHeader h1 {
  color: #1d1d1b !important;
}

[data-section="examples"] .sectionHeader h2 {
  background-color: #1d1d1b !important;
  color: #f9f7dc !important;
}

[data-section="examples"] .sectionContent h1 {
  color: #4d4c4c !important;
}

[data-section="examples"] .sectionContent p {
  color: #4d4c4c !important;
}

[data-3d-stage="examples"] {
  position: absolute;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
