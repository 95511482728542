.cursor {
  width: 30px;
  height: 30px;
  position: fixed;
  top: 0;
  left: 0;
  border: 1px solid #c7d300;
  border-radius: 50%;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.1);
}

.cursor[data-over="yes"] {
  background: rgba(249, 247, 220, 0.729);
  border-color: transparent;

  mix-blend-mode: exclusion;
}

.cursorPointer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.cursorPointer span {
  opacity: 0;
}
.cursor[data-over="yes"] .cursorPointer {
  cursor: none;
}
.cursor[data-over="yes"] .cursorPointer span {
  opacity: 1;
  text-transform: uppercase;
  font-size: 15px;
}

.cursorPlot {
  position: absolute;
  width: 320px;
  height: 160px;
  opacity: 0;
  transform-origin: center center;
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
  border-radius: 16px;
}
