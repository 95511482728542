section[data-section="extras"] {
  background-color: #f9f7dc;
}

section[data-section="extras"] .sectionHeader {
  padding-left: 18%;
}
section[data-section="extras"] .sectionHeader h1 {
  color: #1d1d1b;
}
section[data-section="extras"] .sectionHeader h2 {
  left: -150px;
  background-color: #1d1d1b;
  color: #f9f7dc;
}
section[data-section="extras"] .sectionContent {
  padding-left: 19%;
  padding-bottom: 50px;
}

section[data-section="extras"] .sectionContent h1 {
  color: #4d4c4c;
}

section[data-section="extras"] .sectionContent p {
  color: #4d4c4c;
}

section[data-section="extras"] .subsectionsContainer {
  padding-left: 19%;
  padding-right: 19%;
  display: table;
}

section[data-section="extras"] .subsectionContent {
  display: table-row;
  width: 100%;
  position: relative;
}
section[data-section="extras"] .subsectionContent .subsection {
  position: relative;
}
section[data-section="extras"] .subsectionContent .subsection > h2 {
  display: table-cell;
}
section[data-section="extras"] .subsectionContent .subsection > p {
  display: table-cell;
}

section[data-section="extras"] .number {
  position: relative;
}
section[data-section="extras"] .number .numberValue {
  position: absolute;
  top: -1em;
  z-index: 1;
}
section[data-section="extras"] .number .numberValue span {
  position: relative;
  display: inline-block;
  font-size: 30px;
  color: #1d1d1b;
  background-color: #f9f7dc;
  padding-left: 7px;
  padding-right: 7px;
  background: #f9f7dc;
}
section[data-section="extras"] .number .numberLine {
  position: absolute;
  background: #000;
  height: 1px;
  width: 100%;
  transform-origin: left center;
}
section[data-section="extras"] .subsection {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 70px;
}
section[data-section="extras"] .subsection h2 {
  font-weight: 400;
  font-size: 50px;
  color: #4d4c4c;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 60%;
  padding-right: 20%;
}

section[data-section="extras"] .subsection p {
  font-weight: 300;
  font-size: 15px;
  line-height: 1.6em;
  color: #4d4c4c;
  width: 40%;
}
