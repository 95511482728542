.languagesMenu {
  position: fixed;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 40px;
  left: 26px;
  z-index: 310;
  list-style: none;
  user-select: none;
}
.languagesMenu li {
  display: inline-block;
}
.languagesMenu li a {
  color: #f9f7dc;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  padding: 6px;
  font-size: 12px;
  text-transform: uppercase;
}
.languagesMenu li.active a,
.languagesMenu li a:hover {
  color: #c7d302;
}

.languagesMenu li:after {
  content: " /";
  display: inline-block;
  color: #f9f7dc;
}

.languagesMenu li:last-child:after {
  content: "";
}

.languagesMenu[data-inverted="yes"] li:after {
  color: #1d1d1b;
}
.languagesMenu[data-inverted="yes"] li a {
  color: #585858;
  transition: color 0.5s ease;
}
.languagesMenu[data-inverted="yes"] .active a,
.languagesMenu[data-inverted="yes"] a:hover {
  color: #1d1d1d;
  transition: color 0.5s ease;
}
