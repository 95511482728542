.carColorPicker {
  position: relative;
  width: 100%;
}
.carColorPicker .carColorPickerColors {
  position: relative;
  margin: 0 auto;
  height: 100%;
  padding-left: 160px;
}
.carColorPicker .carColor {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  transform-origin: bottom center;
  display: inline-block;
  margin-right: 10px;
  opacity: 1;
}
.carColorPicker .carColor[data-gloss="yes"] {
  background: linear-gradient(142deg, rgb(189, 189, 189), rgba(66, 66, 66, 0) 90%);
}
.carColorPicker .carColor:hover {
  cursor: pointer;
}
.carColorPicker .carColorDropdown {
  position: relative;
  text-align: center;
  font-size: 15px;
  display: inline-block;
  color: #1d1d1b;
  text-decoration: none;
  width: auto;
  height: auto;
  border: none;
  background: none;
}
.carColorPicker .carColorDropdown .moreArrow {
  position: absolute;
  top: 10px;
  display: block;
  text-align: center;
  left: 0;
  right: 0;
}
.carColorPickerMenu {
  position: relative;
  left: 315px;
  top: 10px;
  border: 1px solid #f9f7dc;
  width: 180px;
  background: #f9f7dc;
  padding: 10px;
  color: #1d1d1b;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.carColorPickerMenu table {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 10px;
  width: 100%;
}
.carColorPickerMenu table tr {
  padding: 4px;
  cursor: pointer;
  pointer-events: all;
}
.carColorPickerMenu table tr td:first-child {
  width: 30px;
}
.carColorPickerMenu table tr td .colorPreview {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 16.5px;
}
.carColorPickerMenu table tr td .colorPreview[data-gloss="yes"] {
  background: linear-gradient(142deg, rgb(189, 189, 189), rgba(66, 66, 66, 0) 90%);
}

.carColorPickerMenu table tr td .colorGroup {
  font-weight: bold;
  display: inline-block;
  padding-top: 15px;
}
.carColorPickerMenu table tr:first-child td .colorGroup {
  padding-top: 0;
}
.carColorPickerMenu table tr td .colorGroup,
.carColorPickerMenu table tr td .colorName {
  color: #1d1d1b;
}
