section[data-section="about"] .sectionContent > h1,
section[data-section="extras"] .sectionContent > h1,
section[data-section="contact"] .sectionContent > h1 {
  position: relative;
  display: inline-block;
}
section[data-section="about"] .sectionContent > h1 span,
section[data-section="extras"] .sectionContent > h1 span,
section[data-section="contact"] .sectionContent > h1 span {
  position: relative;
  display: block;
  white-space: nowrap;
}

section[data-section="examples"] .sectionHeader {
  padding-left: 0;
  padding-right: 0;
}
section[data-section="examples"] .sectionHeader h1,
section[data-section="examples"] .sectionHeader h2 {
  left: auto;
}
section[data-section="examples"] .sectionHeader h1 {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 40px;
}
section[data-section="examples"] .sectionContent {
  padding-left: 25%;
  padding-right: 25%;
}
section[data-section="examples"] .sectionFooter {
  position: absolute;
  width: 100%;
  height: 100px;
  top: 40px;
}

section[data-section="gallery"] .sectionContent > h1,
section[data-section="examples"] .sectionContent h1 {
  position: relative;
  font-size: 60px;
  font-weight: 400;
  word-break: break-word;
}

section[data-section="gallery"] .sectionContent > h1 {
  height: 1.2em;
  overflow: hidden;
}

section[data-section="gallery"] .sectionContent > p,
section[data-section="examples"] .sectionContent > p {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6em;
  word-break: break-word;
}

section[data-section="examples"] .sectionContent > h1 span {
  position: relative;
  display: block;
  white-space: nowrap;
}

section.description {
  font-family: "Neue Haas Unica", sans-serif;
  color: #f9f7dc;
  width: 100%;
  background-color: #1d1d1b;
}

.description h2 {
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 60px;
}

.description p {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5em;
}

.line {
  width: 100%;
  height: 8px;
  position: relative;
  background-color: transparent;
  visibility: hidden; /* DO NOT CHANGE !!! */
}

.sectionFooter {
  position: relative;
  width: 100%;
  height: 100px;
  bottom: -200px;
}

.dg.ac {
  z-index: 1000 !important;
  right: 120px !important;
  top: 20px !important;
}

.blockPage {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
}

span[line="2"] {
  text-transform: lowercase;
}

/*
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(255, 251, 0, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 251, 0, 0.8);
}
*/
