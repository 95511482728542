/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

[data-is-mobile="yes"] .cursor {
  display: none !important;
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .sectionsProgressbarContainer {
    width: 15px;
    height: 40vh;
  }
  .sectionsProgressbarTickLabel {
    font-size: 12px;
  }

  section[data-section="about"] .sectionContent > h1,
  section[data-section="about"] .sectionContent > p,
  section[data-section="about"] .sectionContent .sectionContentDetail {
    padding-left: 20%;
    padding-right: 20%;
  }

  section[data-section="examples"] .sectionContent {
    padding-left: 20%;
    padding-right: 20%;
  }

  section[data-section="gallery"] .sectionHeader {
    padding-left: 25%;
  }
  section[data-section="gallery"] .sectionContent {
    padding-left: 20%;
    padding-right: 20%;
  }

  section[data-section="extras"] .sectionHeader {
    padding-left: 25%;
  }
  section[data-section="extras"] .sectionContent {
    padding-left: 20%;
    padding-right: 20%;
  }
  section[data-section="extras"] .subsectionsContainer {
    padding-left: 15%;
    padding-right: 15%;
  }
  section[data-section="contact"] .sectionContent {
    padding-left: 20%;
    padding-right: 20%;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  [data-is-mobile="no"] .carColorPicker .carColorPickerColors {
    padding-left: 60px;
  }
  [data-is-mobile="yes"] .carColorPicker .carColorPickerColors {
    top: 0;
  }
  [data-is-mobile="yes"] .carColorPicker .carColorPickerMenu {
    top: 40px;
  }
  .socialButtons {
    bottom: 40px;
  }
  .sectionsProgressbarContainer {
    width: 15px;
    height: 40vh;
  }
  .sectionsProgressbarTickLabel {
    font-size: 12px;
  }

  section[data-section="about"] .sectionHeader {
    padding-left: 35%;
  }

  section[data-section="about"] .sectionContent > h1,
  section[data-section="about"] .sectionContent > p,
  section[data-section="about"] .sectionContent .sectionContentDetail {
    padding-left: 20%;
    padding-right: 20%;
  }

  section[data-section="about"] .sectionContent > h1 {
    font-size: 70px;
  }

  section[data-section="about"] .sectionContent .sectionContentDetail h2 {
    font-size: 40px;
  }

  section[data-section="examples"] .sectionContent {
    padding-left: 20%;
    padding-right: 20%;
  }

  section[data-section="gallery"] .sectionHeader {
    padding-left: 35%;
  }
  section[data-section="gallery"] .sectionContent {
    padding-left: 20%;
    padding-right: 20%;
  }
  section[data-section="gallery"] .sectionGalleryContainer {
    padding-left: 0;
    width: 100vw;
    height: calc(0.65 * 100vw);
  }

  section[data-section="extras"] .sectionHeader {
    padding-left: 35%;
  }
  section[data-section="extras"] .sectionContent {
    padding-left: 20%;
    padding-right: 20%;
  }

  section[data-section="extras"] .sectionContent > h1 {
    font-size: 70px;
  }

  section[data-section="extras"] .subsectionsContainer {
    padding-left: 15%;
    padding-right: 15%;
  }
  section[data-section="extras"] .subsectionContent .subsection > h2 {
    font-size: 40px;
  }
  section[data-section="extras"] .number .numberValue span {
    font-size: 28px;
  }

  section[data-section="contact"] .sectionContent {
    padding-left: 20%;
    padding-right: 20%;
  }
  section[data-section="contact"] .sectionContent > h1 {
    font-size: 70px;
  }
}

/* common */

@media (max-width: 767px) {
  section[data-section="about"] .sectionHeader,
  section[data-section="examples"] .sectionHeader,
  section[data-section="gallery"] .sectionHeader,
  section[data-section="extras"] .sectionHeader,
  section[data-section="contact"] .sectionHeader {
    display: flex;
    justify-content: center;
    padding-left: 0;
  }
  section[data-section="about"] .sectionHeader > h1,
  section[data-section="examples"] .sectionHeader > h1,
  section[data-section="gallery"] .sectionHeader > h1,
  section[data-section="extras"] .sectionHeader > h1,
  section[data-section="contact"] .sectionHeader > h1 {
    left: initial;
    margin-right: 15px;
    padding-right: 0;

    display: initial;
    flex: none;
    justify-content: initial;
    padding-right: 0;
  }
  section[data-section="about"] .sectionHeader > h2,
  section[data-section="examples"] .sectionHeader > h2,
  section[data-section="gallery"] .sectionHeader > h2,
  section[data-section="extras"] .sectionHeader > h2,
  section[data-section="contact"] .sectionHeader > h2 {
    left: inherit;

    font-size: 12px;
    padding: 2px;
    padding-left: 3px;
    padding-right: 0;
    line-height: 1.2em;
  }

  [data-is-mobile="yes"] .carColorPicker .carColorPickerMenu {
    left: calc(50vw - 180px / 2);
    height: 60vh;
    top: 180px;
  }

  [data-is-mobile="yes"] .carColorPicker .carColorPickerColors {
    position: absolute;
    top: 150px;
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  [data-is-mobile="yes"] .carColorPicker .carColorPickerColors {
    top: 200px;
  }
  [data-is-mobile="yes"] .carColorPicker .carColorPickerMenu {
    top: 240px;
  }

  .logo-wrapper {
    display: none;
  }
  .miniPageMenu {
    right: 20px;
    top: 20px;
  }
  .miniPageMenu li:first-child {
    margin-right: 150px;
  }
  .languagesMenu {
    left: 20px;
    top: 20px;
  }
  .languagesMenu li {
    display: block;
  }
  .languagesMenu li a {
    padding-left: 0;
  }
  .languagesMenu li:after {
    display: none;
  }
  .socialButtons {
    right: 20px;
    bottom: 20px;
  }

  .bigMenuItems {
    top: 100px;
  }
  .bigMenuItems li a {
    padding-left: 30px;
  }
  .bigMenuItems li .sectionCount strong {
    font-size: 5em;
  }
  .bigMenuItems li span {
    font-size: 2em;
    margin-left: 20px;
  }

  .sectionsProgressbarContainer {
    width: 10px;
    height: 35vh;
    left: 20px;
  }
  .sectionsProgressbarTickLabel {
    font-size: 10px;
  }

  section .sectionHeader > h1 {
    padding-top: 95px;
  }
  section .sectionHeader > h2 {
    margin-top: 114px;
  }

  section {
    padding-bottom: 90px;
  }
  section[data-section="extras"] {
    padding-bottom: 40px !important;
  }

  section[data-section="about"] .sectionHeader > h1 {
    font-size: 8em;
  }

  section[data-section="about"] .sectionContent > h1 {
    font-size: 60px;
  }

  section[data-section="about"] .sectionContent > h1,
  section[data-section="about"] .sectionContent > p,
  section[data-section="about"] .sectionContent .sectionContentDetail {
    padding-left: 15%;
    padding-right: 8%;
  }

  section[data-section="about"] .sectionContent > p {
    font-size: 18px;
  }
  section[data-section="about"] .sectionContent > h1 span {
    white-space: normal;
  }

  section[data-section="about"] .sectionContent .sectionContentDetail h2 {
    font-size: 32px;
  }
  section[data-section="about"] .sectionContent .MoreDetailsButton span {
    font-size: 32px;
  }

  section[data-section="examples"] .sectionHeader > h1 {
    font-size: 8em;
  }

  section[data-section="examples"] .sectionContent {
    padding-left: 15%;
    padding-right: 8%;
  }
  section[data-section="examples"] .sectionContent > h1 {
    font-size: 55px;
  }
  section[data-section="examples"] .sectionContent > p {
    font-size: 15px;
  }
  section[data-section="examples"] .sectionContent > h1 span {
    white-space: normal;
  }

  section[data-section="gallery"] .sectionHeader > h1 {
    font-size: 8em;
  }

  section[data-section="gallery"] .sectionContent {
    padding-left: 15%;
    padding-right: 8%;
  }
  section[data-section="gallery"] .sectionContent > h1 {
    font-size: 55px;
  }
  section[data-section="gallery"] .sectionContent > p {
    font-size: 15px;
  }
  section[data-section="gallery"] .sectionContent > h1 span {
    white-space: normal;
  }

  section[data-section="gallery"] .sectionGalleryContainer {
    padding-left: 0;
    width: 100vw;
    height: calc(0.65 * 100vw);
  }

  section[data-section="gallery"] .sectionContentDescription {
    width: 100%;
  }

  section[data-section="extras"] .sectionHeader > h1 {
    font-size: 8em;
  }

  section[data-section="extras"] .sectionContent {
    padding-left: 15%;
    padding-right: 8%;
  }
  section[data-section="extras"] .sectionContent > h1 {
    font-size: 60px;
  }
  section[data-section="extras"] .sectionContent > p {
    font-size: 18px;
  }
  section[data-section="extras"] .sectionContent > h1 span {
    white-space: normal;
  }

  section[data-section="extras"] .subsectionsContainer {
    padding-left: 13%;
    padding-right: 8%;
  }
  section[data-section="extras"] .subsectionContent {
    display: inherit;
  }
  section[data-section="extras"] .subsectionContent .subsection > h2 {
    font-size: 32px;
    display: inherit;
    margin-bottom: 15px;
    padding-right: 0;
    width: auto;
  }

  section[data-section="extras"] .number .numberValue span {
    font-size: 28px;
  }

  section[data-section="contact"] .sectionHeader > h1 {
    font-size: 8em;
  }

  section[data-section="contact"] .sectionContent {
    padding-left: 15%;
    padding-right: 8%;
  }
  section[data-section="contact"] .sectionContent > h1 {
    font-size: 60px;
  }
  section[data-section="contact"] .sectionContent > h2 {
    font-size: 14px;
  }
  section[data-section="contact"] .sectionContent > p {
    font-size: 15px;
  }
  section[data-section="contact"] .sectionContent > h1 span {
    white-space: normal;
  }

  section[data-section="contact"] .sectionFooter {
    font-size: 12px;
    padding-bottom: 0;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  [data-is-mobile="yes"] .carColorPicker .carColorPickerColors {
    top: 150px;
  }
  [data-is-mobile="yes"] .carColorPicker .carColorPickerMenu {
    top: 190px;
  }

  .bookingForm {
    transform: scale(0.75);
  }

  .logo-wrapper {
    display: none;
  }
  .miniPageMenu {
    right: 10px;
    top: 20px;
  }
  .miniPageMenu li:first-child {
    margin-right: 60px;
  }
  .languagesMenu {
    left: 10px;
    top: 20px;
  }
  .languagesMenu li {
    display: block;
  }
  .languagesMenu li a {
    padding-left: 0;
  }
  .languagesMenu li:after {
    display: none;
  }
  .socialButtons {
    right: 10px;
    bottom: 20px;
  }
  .sectionsProgressbarContainer {
    width: 10px;
    height: 35vh;
    left: 10px;
  }
  .sectionsProgressbarTickLabel {
    font-size: 10px;
    right: calc(-1em - 10px) !important;
  }
  .sectionsProgressbarTickLabel a span {
    border: none !important;
  }

  .bigMenuItems {
    top: 100px;
  }
  .bigMenuItems li a {
    padding-left: 30px;
  }
  .bigMenuItems li .sectionCount strong {
    font-size: 4em;
  }
  .bigMenuItems li span {
    font-size: 1.5em;
    margin-left: 20px;
  }

  section .sectionHeader > h1 {
    padding-top: 55px;
  }
  section .sectionHeader > h2 {
    margin-top: 74px;
  }

  section {
    padding-bottom: 50px;
  }

  section[data-section="extras"] {
    padding-bottom: 0 !important;
  }

  section[data-section="about"] .sectionHeader > h1 {
    font-size: 7em;
  }

  section[data-section="about"] .sectionContent > h1,
  section[data-section="about"] .sectionContent > p,
  section[data-section="about"] .sectionContent .sectionContentDetail {
    padding-left: 13%;
    padding-right: 8%;
  }

  section[data-section="about"] .sectionContent > h1 {
    font-size: 40px;
  }
  section[data-section="about"] .sectionContent > p {
    font-size: 18px;
  }
  section[data-section="about"] .sectionContent > h1 span {
    white-space: normal;
  }

  section[data-section="about"] .sectionContent .sectionContentDetail h2 {
    font-size: 28px;
  }
  section[data-section="about"] .sectionContent .MoreDetailsButton span {
    font-size: 28px;
  }

  section[data-section="examples"] .sectionHeader > h1 {
    font-size: 7em;
  }

  section[data-section="examples"] .sectionContent {
    padding-left: 13%;
    padding-right: 8%;
  }

  section[data-section="examples"] .sectionContent > h1 {
    font-size: 35px;
  }
  section[data-section="examples"] .sectionContent > p {
    font-size: 15px;
  }
  section[data-section="examples"] .sectionContent > h1 span {
    white-space: normal;
  }

  section[data-section="gallery"] .sectionHeader > h1 {
    font-size: 7em;
  }

  section[data-section="gallery"] .sectionContent {
    padding-left: 13%;
    padding-right: 8%;
  }
  section[data-section="gallery"] .sectionContent > h1 {
    font-size: 35px;
  }
  section[data-section="gallery"] .sectionContent > p {
    font-size: 15px;
  }
  section[data-section="gallery"] .sectionContent > h1 span {
    white-space: normal;
  }

  section[data-section="gallery"] .sectionContentDescription {
    width: auto;
  }

  section[data-section="gallery"] .sectionGalleryContainer {
    padding-left: 0;
    width: 100vw;
    height: calc(0.65 * 100vw);
  }

  section[data-section="gallery"] .sectionGalleryContainer .sectionGalleryPreview {
    padding-top: 10vh;
  }

  section[data-section="extras"] .sectionHeader > h1 {
    font-size: 7em;
  }

  section[data-section="extras"] .sectionContent {
    padding-left: 13%;
    padding-right: 8%;
  }
  section[data-section="extras"] .sectionContent > h1 {
    font-size: 40px;
  }
  section[data-section="extras"] .sectionContent > p {
    font-size: 18px;
  }
  section[data-section="extras"] .sectionContent > h1 span {
    white-space: normal;
  }

  section[data-section="extras"] .subsectionsContainer {
    padding-left: 13%;
    padding-right: 8%;
  }
  section[data-section="extras"] .subsectionContent {
    display: inherit;
  }
  section[data-section="extras"] .subsectionContent .subsection > h2 {
    font-size: 28px;
    display: inherit;
    margin-bottom: 15px;
    padding-right: 0;
    width: auto;
  }

  section[data-section="extras"] .number .numberValue span {
    font-size: 25px;
  }

  section[data-section="contact"] .sectionHeader > h1 {
    font-size: 7em;
  }

  section[data-section="contact"] .sectionContent {
    padding-left: 13%;
    padding-right: 8%;
  }
  section[data-section="contact"] .sectionContent > h1 {
    font-size: 40px;
  }
  section[data-section="contact"] .sectionContent > h2 {
    font-size: 14px;
  }
  section[data-section="contact"] .sectionContent > p {
    font-size: 15px;
  }
  section[data-section="contact"] .sectionContent > h1 span {
    white-space: normal;
  }

  section[data-section="contact"] .sectionFooter {
    font-size: 12px;
    padding-bottom: 0;
  }

  section[data-section="contact"] .sectionContentColumns {
    flex-direction: column;
  }
  section[data-section="contact"] .sectionContentColumns address:last-child {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

/* ----------- iPad Pro ----------- /
/ Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
}
