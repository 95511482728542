.socialButtons {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 2000;
  bottom: 40px;
  right: 60px;
}
.socialButtons li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.socialButtons li a {
  text-decoration: none;
  color: #c7d300;
  font-size: 32px;
}

.socialButtons[data-inverted="yes"] li:after {
  color: #1d1d1b;
}
.socialButtons[data-inverted="yes"] li a {
  color: #585858;
  transition: color 0.5s ease;
}
.socialButtons[data-inverted="yes"] .active a,
.socialButtons[data-inverted="yes"] a:hover {
  color: #1d1d1d;
  transition: color 0.5s ease;
}
