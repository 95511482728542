.grecaptcha-badge {
  visibility: hidden;
}
.bookingForm {
  font-family: "Neue Haas Unica", sans-serif;
  position: relative;
}
.bookingForm fieldset {
  border-radius: 12px;
}
.bookingForm fieldset,
.bookingForm fieldset legend {
  background: #fff;
  border: none;
}
.bookingForm fieldset legend {
  padding: 12px;
  font-weight: bold;
  border-radius: 12px;
  position: relative;
  margin: 0 auto;
}
.bookingForm dl {
  margin: 0;
}
.bookingForm dl dt {
  padding-bottom: 10px;
  font-weight: bold;
  display: none;
}
.bookingForm dl dd {
  margin-bottom: 10px;
  margin-left: 0;
}
.bookingForm dl dd:last-child {
  margin-bottom: 0;
}
.bookingForm dl dd input[type="text"],
.bookingForm dl dd input:not([type="checkbox"]) {
  border-radius: 6px;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 4px;
  width: 100%;
  text-align: center;
}
.bookingForm dl dd input:not([type="checkbox"]):focus {
  border-color: #000;
  outline: none;
}
.bookingForm input.flatpickr-input {
  display: none;
}
.bookingFormMessage {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  text-align: center;
}
.bookingFormMessage img {
  width: 160px;
}
.bookingFormMessage p {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
}
.bookingFormProgressIndicator {
  width: 100%;
  height: 78px;
  display: none;
}
.bookingFormProgressIndicatorImage {
  background-image: url("/img/ajax-loader.gif");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 32px;
  height: 100%;
  margin: 0 auto;
}
.bookingFormActions {
  text-align: center;
}
.bookingFormActions button {
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 6px;
  outline: none;
  cursor: pointer;
  font-size: 13px;
  min-width: 120px;
  position: relative;
  margin: 0 auto;
}
.bookingFormActions button:hover:not(:disabled) {
  border-color: #333;
}
.bookingFormActions button:focus {
  border-color: #000;
}
.bookingForm #captcha {
  height: 78px;
}
.bookingForm #captcha > div {
  text-align: center;
  margin: 0 auto;
  transform: scale(0.65);
}
.bookingForm fieldset legend {
  position: relative;
}
.bookingFormCloseButton {
  position: absolute;
  right: 14px;
  border: none;
  background: #fff;
  padding: 10px;
  top: 0;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
}
.bookingFormCloseButton:focus {
  outline: none;
}
.bookingForm .serviceChoices {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.bookingForm .serviceChoicesTitle {
  padding: 5px;
  font-weight: bold;
}
.bookingForm .serviceChoicesList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.bookingForm .serviceChoicesList label span {
  padding-left: 10px;
}
.bookingForm .flatpickr-calendar.inline {
  transform: scale(0.9);
}
