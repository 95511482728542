section[data-section="contact"] .sectionHeader {
  padding-left: 0;
  padding-right: 0;
}
section[data-section="contact"] .sectionHeader h1,
section[data-section="contact"] .sectionHeader h2 {
  left: auto;
}
section[data-section="contact"] .sectionHeader h1 {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 40px;
}

section[data-section="contact"] .sectionContent {
  padding-right: 25%;
  padding-left: 25%;
}
section[data-section="contact"] .sectionContent .sectionContentColumns {
  display: flex;
}
section[data-section="contact"] .sectionContent .sectionContentColumns address {
  flex: 50%;
  color: #f9f7dc;
  font-style: normal;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6em;
}
section[data-section="contact"] .sectionContent .sectionContentColumns address h2 {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
  color: #c7d300;
}
section[data-section="contact"] .sectionContent .sectionContentColumns address a {
  color: #f9f7dc;
  text-decoration: none;
}

section[data-section="contact"] .sectionFooter {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 50px;
  color: #f9f7dc;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6em;
}
