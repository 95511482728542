.AwesomeButton {
  position: relative;
  outline: none;

  background-color: transparent;
  border: none;
  padding: 0;
  color: #c7d300;
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
}
.AwesomeButton:hover span {
  color: #1d1d1b;
}
.AwesomeButtonElements {
  padding: 5px;
}
.AwesomeButton i,
.AwesomeButton span {
  position: relative;
  z-index: 1;
  pointer-events: none;
}
.AwesomeButton span {
  display: inline-block;
}
.AwesomeButton .buttonHighlight {
  pointer-events: none;
  position: absolute;
  background: #c7d300;
  top: 0;
  left: 16px;
  width: 100%;
  height: 100%;
  z-index: 0;
  transform-origin: left center;
  transform: scaleX(0);
}
