section[data-section="home"] {
  position: relative;
  z-index: 2;
  height: 100vh;
  padding-bottom: 0;
}

.bg-home {
  position: relative;
  width: 100%;
  height: 100%;
}
.bg-face {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(29, 29, 27);
  background: linear-gradient(
    180deg,
    rgba(29, 29, 27, 0) 0%,
    rgb(29 29 27 / 0%) 35%,
    rgb(29 29 27 / 83%) 80%,
    rgba(29, 29, 27, 1) 100%
  );
}
.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.bg-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
  object-fit: cover;
}

.logo-wrapper {
  position: relative;
  width: 100%;
  z-index: 3;
}
.logo {
  background-image: url("/img/logo.svg");
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: center;
  margin: 0 auto;
}

.taglineContainer {
  position: absolute;
  width: 100%;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tagline {
  user-select: none;
  filter: drop-shadow(2px 4px 6px #1d1d1b);
}
.tagline h1 {
  margin: 0;
  position: absolute;
  text-transform: uppercase;
  font-size: 10vw;
  font-family: "Neue Haas Unica", sans-serif;
  font-weight: 800;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #c7d300;
}

@supports ((text-stroke: 1px #c7d300) or (-webkit-text-stroke: 1px #c7d300)) {
  h1[data-scope="outline"] {
    color: transparent;
    -webkit-text-stroke: 1px #c7d300;
    text-stroke: 1px #c7d300;
  }
}

.tagline h1[data-scope="focal"] {
  clip-path: circle(0px at 0px 0px);
}

.tagline h1[data-scope="fill"] {
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
}
