.miniPageMenu {
  position: fixed;
  top: 40px;
  z-index: 310;
  font-size: 24px;
  color: #fff;
  margin: 0;
  padding: 0;
  list-style: none;
  right: 60px;
  user-select: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.miniPageMenu li {
  display: inline-block;
}
.miniPageMenu li:first-child {
  margin-right: 40px;
}
.miniPageMenu li a[data-action="toggle.menuPage"] {
  display: block;
  margin: 0;
  padding: 0;
}

.menuPageView {
  position: fixed;
  z-index: 300;
  left: 0;
  width: 100%;
  top: -100vh;
  height: 100vh;
  background-color: #0d1d1b;
  background-size: cover;
  background-position: bottom;
  overflow: hidden;
}

.bigMenuItems {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Neue Haas Unica", sans-serif;
  font-weight: 300;
  color: #f9f7dc;
}
.bigMenuItems .sectionCount {
  display: inline-block;
  position: relative;
}
.bigMenuItems .sectionCountSeparator {
  position: absolute;
  background-color: #f9f7dc;
  height: 2px;
  width: 240px;
  right: 0;
  bottom: 14px;
}
.bigMenuItems li {
  position: relative;
  user-select: none;
  margin-bottom: 20px;
}
.bigMenuItems li a {
  padding-left: 120px;
  color: #f9f7dc;
  text-decoration: none;
  position: relative;
  display: block;
}
.bigMenuItems li .sectionCount strong {
  font-size: 6em;
  text-align: right;
  font-family: "Neue Haas Unica", sans-serif;
}
.bigMenuItems li span {
  font-size: 2.5em;
  text-transform: capitalize;
  margin-left: 40px;
  font-style: normal;
  font-family: "Neue Haas Unica", sans-serif;
}
.bigMenuItems li .sectionTitle,
.bigMenuItems li .sectionLabel {
  position: relative;
  display: inline-block;
}
.bigMenuItems li .sectionTitle {
  z-index: 6;
}
.bigMenuItems li .sectionLabel {
  display: inline-block;
  pointer-events: none;
}
.bigMenuItems li:nth-child(1) .sectionTitle {
  left: 0%;
}
.bigMenuItems li:nth-child(2) .sectionTitle {
  left: 8%;
}
.bigMenuItems li:nth-child(3) .sectionTitle {
  left: 16%;
}
.bigMenuItems li:nth-child(4) .sectionTitle {
  left: 24%;
}
.bigMenuItems li:nth-child(5) .sectionTitle {
  left: 32%;
}
.bigMenuItems li .sectionBack {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  z-index: 5;
  background: rgba(199, 211, 2, 1);
}
.bigMenuItems li a:hover {
  cursor: pointer;
}
.bigMenuItems li a:hover .sectionCount {
  animation: animateMenuHighlightCount 0.5s 1;
}

.OpenIndicator {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.OpenIndicatorL1,
.OpenIndicatorL2 {
  background: #c7d300;
}

.miniPageMenu[data-inverted="yes"] .AwesomeButton {
  color: #1d1d1d;
}
.miniPageMenu[data-inverted="yes"] .OpenIndicatorL1,
.miniPageMenu[data-inverted="yes"] .OpenIndicatorL2 {
  background: #1d1d1d;
}

.bgs {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  display: none;
  top: 0;
  left: 0;
  opacity: 0;
}

.bg {
  animation: slide 15s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #4e4c4c 50%, #c7d302 50%);
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 7s;
}

.bg3 {
  animation-duration: 9s;
}

@keyframes animateMenuHighlightCount {
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
