.sectionsProgressbarContainer {
  position: fixed;
  z-index: 200;
  width: 20px;
  height: 50vh;
  left: 30px;
  top: calc(50vh - 25vh);
}

article {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

section {
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat !important;
  padding-bottom: 150px;
}

section > .sectionHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 25%;
}
section .sectionHeader > h1 {
  position: relative;
  margin: 0;
  padding: 0;
  display: inline-block;
  color: #c7d300;
  font-size: 10em;
  font-weight: 300;
  padding-top: 164px;
  left: -200px;
}
section .sectionHeader > h2 {
  position: relative;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 4px;
  color: #1d1d1b;
  background-color: #c7d300;
  text-transform: uppercase;
  height: 1em;
  padding: 4px;
  padding-right: 0;
  margin-top: 188px;
  left: -170px;
  display: inline-block;
}

section .sectionContent {
  padding-left: 25%;
  padding-right: 25%;
}

section .sectionContent > h1 {
  font-weight: 400;
  font-size: 80px;
  line-height: 1.2em;
  margin-top: 0px;
  margin-bottom: 40px;
  color: #f9f7dc;
}
section .sectionContent > p {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.6em;
  color: #f9f7dc;
}
section .sectionContentDetail h2 {
  font-weight: 400;
  font-size: 50px;
  color: #c7d300;
  margin-bottom: 0px;
}
section .sectionContentDetail dl dd {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
section .sectionContentDetail dl dd p {
  font-weight: 300;
  font-size: 15px;
  line-height: 1.6em;
  color: #f9f7dc;
}
