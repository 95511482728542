section[data-section="about"] .sectionContent {
  padding-left: 0;
  padding-right: 0;
}

section[data-section="about"] .sectionContent > h1,
section[data-section="about"] .sectionContent > p,
section[data-section="about"] .sectionContent .sectionContentDetail {
  padding-left: 25%;
  padding-right: 25%;
}

section[data-section="about"] .sectionContentDetail dl dd {
  position: relative;
  border-bottom: 1px solid rgba(199, 211, 0, 0.3);
}
section[data-section="about"] .sectionContentDetail dl dd > p {
  margin-top: 0;
  position: relative;
  display: block;
}
section[data-section="about"] .sectionContentDetail dl dd > p[data-expanded="yes"] {
  display: block;
  height: auto;
  opacity: 1;
}
section[data-section="about"] .sectionContentDetail dl dd > p[data-expanded="no"] {
  display: none;
  height: 0;
  opacity: 0;
}
section[data-section="about"] .sectionContentDetailFrame {
  background-image: url(/img/about/v2.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  height: calc(100vw / 2.17);
  width: 100vw;
  position: relative;
  margin-top: 60px;
}
section[data-section="about"] .sectionContentDetailFrame .gradientFader {
  background: linear-gradient(
    180deg,
    rgb(29 29 27) 0%,
    rgb(158 158 158 / 0) 35%,
    rgb(29 29 27 / 78%) 80%,
    rgba(29, 29, 27, 1) 100%
  );
  position: relative;
  width: 100%;
  height: 100%;
}

section[data-section="about"] .MoreDetailsButton {
  display: block;
  text-align: right;
  text-decoration: none;
}

section[data-section="about"] .MoreDetailsButton span {
  color: #c7d300;
  font-weight: 100;
  font-size: 50px;
  display: inline-block;
  text-align: center;
  width: 50px;
}
