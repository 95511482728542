section[data-section="gallery"] {
  padding-bottom: 0;
}
section[data-section="gallery"] .sectionHeader {
  padding-left: 18%;
}
section[data-section="gallery"] .sectionHeader h2 {
  left: -150px;
}
section[data-section="gallery"] .sectionContent {
  padding-left: 19%;
  padding-right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
section[data-section="gallery"] .sectionContentDescription {
  white-space: normal;
}
section[data-section="gallery"] .sectionGalleryContainer {
  position: relative;
  height: 74vh;
  padding-left: 19%;
  width: calc(100vw - 19% - 15px);
}
section[data-section="gallery"] .sectionGalleryContainer .sectionGalleryPreview {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}